import React from 'react';
import Layout from '../components/Layout';
import PageTitle from '../components/PageTitle';
import AardeYamzLayout from '../components/aardeyamz/aardeyamzLayout';

export default function AardeYamzPage() {
    return (
        <Layout>
            <PageTitle title="AardeYamz" head="aardeyamzhead" />
            <AardeYamzLayout title="What's in a name?" />
        </Layout>
    )
}
