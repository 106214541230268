import React from 'react';
import Fade from 'react-reveal/Fade';
import Title from '../SectionTitle';
import { Card, Container, Row, Col } from 'react-bootstrap';
import config from '../../../config';

export default function aardeyamzLayout({ title }) {
    return (
        <div>
            <section id="aardeyamz" className="aardeyamz-section" >
                <Container className="aardeyamz-wrapper">
                    <Title title={title} />
                    {
                        config.aardeyamz.map(
                            aarde => {
                                const { title, time, language, pronounciation, content } = aarde;
                                return (
                                    <Fade bottom duration={1000} delay={600} distance="30px">
                                        <Row className="aardeyamz-wrapper">
                                            <Col md={8} sm={12}>
                                                <Card>
                                                    <Card.Body >
                                                        <Card.Title className='card_title text-center'><b>{title}</b></Card.Title>
                                                        <Card.Subtitle className="mb-2 text">{time}</Card.Subtitle>
                                                        <Card.Text className="mb-2 text">
                                                            <ul>
                                                                <li>language(s): {language}</li>
                                                                <li>pronounciation: {pronounciation}</li>
                                                                {
                                                                    content.map(
                                                                        c => {
                                                                            return <li>{c}</li>;
                                                                        }
                                                                    )
                                                                }
                                                            </ul>
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Fade>
                                );
                            }
                        )
                    }
                </Container>
            </section>
        </div>
    );
};